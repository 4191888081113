<template>
  <div>
    <card>
      <product-gallery-manage @added="payload => $emit('added', payload)" v-model="modalActive" />
    </card>
    <card>
      <div slot="header" class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">4 kluczowe cechy</h3>
        </div>
      </div>
      <base-input
        v-for="(element, index) in cFourElements"
        :key="`element-${element.code}`"
        :label="`${index+1}. ${element.name}`"
        v-model="formData.properties[element.code]"
      />
    </card>
    <card>
      <div slot="header" class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Korzyści konsumenckie</h3>
        </div>
      </div>
      <div v-for="(item, index) in cBenefits" :key="`ingredient-${index+1}`">
        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Procent %"
              v-model="item.percent"
            />
          </div>
          <div class="col-md-8">
            <base-input
              label="Korzyść"
            >
              <textarea class="form-control" rows="8" v-model="item.text"></textarea>
            </base-input>
          </div>
        </div>
        <div class="text-right">
          <div
            class="btn btn-outline-danger mb-4"
            @click="deleteFromArray(index, 'consumer_benefits')"
          >
            Usuń korzyść
          </div>
        </div>
        <hr class="mb-3" />
      </div>
      <button class="btn btn-primary btn-block" @click="addToArray('consumer_benefits', {
        'percent': '',
        'text': ''
      })">Dodaj kolejną korzyść</button>
    </card>
    <card>
      <div slot="header" class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Kluczowe składniki</h3>
        </div>
      </div>
      <div v-for="(item, index) in cKeyIngredients" :key="`ingredient-${index+1}`">
        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Składnik"
              v-model="item.ingredient"
            />
          </div>
          <div class="col-md-8">
            <base-input
              label="Działanie"
            >
              <textarea class="form-control" rows="8" v-model="item.text"></textarea>
            </base-input>
          </div>
        </div>
        <div class="text-right">
          <div
            class="btn btn-outline-danger mb-4"
            @click="deleteFromArray(index, 'ingredients')"
          >
            Usuń składnik
          </div>
        </div>
        <hr class="mb-3" />
      </div>
      <button class="btn btn-primary btn-block" @click="addToArray('ingredients', {
        ingredient: '',
        text: ''
      })">Dodaj kolejny składnik</button>
    </card>
    <card>
      <div slot="header" class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Kompozycja zapachowa</h3>
        </div>
      </div>
      <base-input
        v-for="(element, index) in cNotes"
        :key="`element-${element.code}`"
        :label="`${index+1}. ${element.name}`"
        v-model="formData.properties[element.code]"
      />
    </card>
    <card>
      <div slot="header" class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Atrybuty</h3>
        </div>
      </div>

      <div class="pl-lg-4">
        <div v-for="prop in otherAttributes" :key="prop.id">
          <base-input
            :label="prop.name"
            v-model="formData.properties[prop.code]"
            v-if="!Array.isArray(formData.properties[prop.code]) && prop.type === 'input'"
          />
          <base-input :label="prop.name" v-if="!Array.isArray(formData.properties[prop.code]) && prop.type === 'textarea'">
            <rich-editor-component v-model="formData.properties[prop.code]" />
          </base-input>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import ProductGalleryManage from "@/components/TailwindComponents/ProductGalleryManage";
import RichEditorComponent from "@/components/RichEditor/RichEditorComponent.vue";

export default {
  name: 'ProductPropertyForm',
  components: {
    ProductGalleryManage,
    RichEditorComponent
  },
  props: {
    formData: Object,
    propertyOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      modalActive: false
    }
  },
  methods: {
    propertyName(prop) {
      return prop.name;
    },
    propertyValue(prop) {
      const value = this.formData.properties[prop.code];
      return Array.isArray(value) ? 'ARRAY (mock)' : (value ?? '--');
    },
    deleteFromArray(itemIndex, arrayKey) {
      this.formData.properties[arrayKey] = this.formData.properties[arrayKey].filter( (item, index) => index !== itemIndex )
    },
    addToArray(arrayKey, object) {
      if ( this.formData.properties[arrayKey] ) {
        this.formData.properties[arrayKey].push(object)
      } else {
        this.formData.properties[arrayKey] = [object]
      }
    }
  },
  computed: {
    cPropertyOptions() {
      return this.propertyOptions.map( prop => {
        return {
          ...prop,
          type: ['application', 'ingredients_effect', 'how_to_recycle'].includes(prop.code) ? 'textarea' : 'input'
        }
      })
    },
    cFourElements() {
      const order = [
        '4_elements_product_type',
        '4_elements_packaging',
        '4_elements_for_whom',
        '4_elements_effects'
      ]
      return this.propertyOptions.filter( prop => prop.code.includes('4_elements')).sort( (a, b) => order.indexOf(a.code) - order.indexOf(b.code))
    },
    cNotes() {
      const order = [
        'fragrance_head',
        'fragrance_heart',
        'fragrance_base',
      ]
      return this.propertyOptions.filter( prop => order.includes(prop.code)).sort( (a, b) => order.indexOf(a.code) - order.indexOf(b.code))
    },
    cKeyIngredients() {
      return this.formData.properties?.ingredients ?? []
    },
    cBenefits() {
      return this.formData.properties?.['consumer_benefits'] ?? []
    },
    otherAttributes() {
      return this.cPropertyOptions.filter(option => [
        'application',
        'how_to_recycle',
        'volume',
        'ingredients_effect'
      ].includes(option.code))
    }
  }
};
</script>
